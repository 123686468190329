import axios from 'axios';
import { config } from '../config';

const api = axios.create({
  baseURL: config.API_URL,
  headers: {
    'X-RapidAPI-Host': 'footapi7.p.rapidapi.com',
    'X-RapidAPI-Key': config.API_KEY
  }
});

export const fetchTeamMatchesForSeason = async (teamId, season = '24/25') => {
  try {
    const [previousMatches, nextMatches] = await Promise.all([
      api.get(`/team/${teamId}/matches/previous/0`),
      api.get(`/team/${teamId}/matches/next/0`)
    ]);

    const allMatches = [
      ...(previousMatches.data?.events || []),
      ...(nextMatches.data?.events || [])
    ].filter(match => match.season?.name?.includes(season));

    return allMatches;
  } catch (error) {
    console.error('Sezon maçları alınamadı:', error);
    throw error;
  }
};

export const fetchMatchDetails = async (matchId) => {
  const response = await api.get(`/match/${matchId}`);
  return response.data;
};

export const fetchMatchStatistics = async (matchId) => {
  const response = await api.get(`/match/${matchId}/statistics`);
  return response.data;
};

export const fetchTeamLogo = async (teamId) => {
  try {
    // Önce public/logos/team klasöründen logo almayı dene
    const localLogo = `/logos/team/${teamId}.png`;
    try {
      const response = await fetch(localLogo);
      if (response.ok) {
        return localLogo;
      }
    } catch (e) {
      // Logo local'de yok, API'dan indirelim
    }

    // API'dan logo indir
    const response = await axios.get(`${config.API_URL}/team/${teamId}/image`, {
      headers: {
        'X-RapidAPI-Key': config.API_KEY,
        'X-RapidAPI-Host': config.API_HOST,
      },
      responseType: 'blob'
    });

    if (response.data) {
      return URL.createObjectURL(response.data);
    }
    
    throw new Error('Logo alınamadı');
  } catch (error) {
    console.error(`Takım logosu alınamadı (ID: ${teamId}):`, error);
    return '/default-team-logo.png';
  }
};

export const fetchAndSaveTournamentLogo = async (tournamentId) => {
  try {
    // Önce local'de logo var mı kontrol et
    const localLogo = `/logos/tournament/${tournamentId}.png`;
    try {
      const response = await fetch(localLogo);
      if (response.ok) {
        return localLogo;
      }
    } catch (e) {
      // Logo local'de yok, API'dan indirelim
    }

    // API'dan logo indir
    const response = await api.get(`/tournament/${tournamentId}/image`, {
      responseType: 'blob'
    });
    
    if (response.data) {
      // Backend'e logo kaydetme isteği
      const formData = new FormData();
      formData.append('logo', response.data);
      formData.append('tournamentId', tournamentId);
      
      await axios.post(`${config.BACKEND_URL}/api/save-tournament-logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return `/logos/tournament/${tournamentId}.png`;
    }
    
    throw new Error('Turnuva logosu alınamadı');
  } catch (error) {
    console.error(`Turnuva logosu alınamadı (ID: ${tournamentId}):`, error);
    return '/default-tournament-logo.png';
  }
};

// Geriye dönük uyumluluk için alias'lar
export const fetchTournamentLogo = fetchAndSaveTournamentLogo;
  