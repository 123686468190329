import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // URL'den slug ve teamId almak için kullanıyoruz
import SelectBox from '../components/SelectBox'; // Import SelectBox component
import StatisticsTable from '../components/StatisticsTable'; // Import StatisticsTable component
import Lottie from 'react-lottie'; // Lottie animation
import { fetchTeamLogo } from '../services/api';

const Statistics = () => {
  const { teamSlug, teamId } = useParams(); // URL'den teamSlug ve teamId alıyoruz
  const [tournaments, setTournaments] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [animationData, setAnimationData] = useState(null); // To store animation data
  const [error, setError] = useState(null);
  const [teamLogos, setTeamLogos] = useState({});

  const API_KEY = 'a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023';
  const API_HOST = 'footapi7.p.rapidapi.com';

  // Fetch the Lottie animation file
  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch('/load.json');
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error('Error loading animation:', error);
      }
    };

    fetchAnimationData();
  }, []);

  // Lottie animation configuration
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // Loaded dynamically
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Utility function to add retries in case of 429 errors (rate limiting)
  const makeApiCallWithRetries = async (options, maxRetries = 3, delay = 2000) => {
    let attempts = 0;
    while (attempts < maxRetries) {
      try {
        const response = await axios.request(options);
        return response; // Return the response if the request is successful
      } catch (error) {
        if (error.response && error.response.status === 429) {
          attempts++;
          console.warn(`429 Rate Limit: Retrying... attempt ${attempts}`);
          await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
        } else {
          throw error; // Throw the error if it's not a 429 or if max retries reached
        }
      }
    }
    throw new Error('Too many attempts made, still getting 429');
  };

  // Fetching tournament and season data
  useEffect(() => {
    if (!teamId) {
      setError('Takım ID bulunamadı.');
      setLoading(false);
      return;
    }

    const fetchSeasonsAndTournaments = async () => {
      setLoading(true);
      try {
        const options = {
          method: 'GET',
          url: `https://footapi7.p.rapidapi.com/api/team/${teamId}/standings/seasons`,
          headers: {
            'x-rapidapi-key': API_KEY,
            'x-rapidapi-host': API_HOST,
          },
        };

        const response = await makeApiCallWithRetries(options);
        const tournamentSeasons = response.data.tournamentSeasons;

        setTournaments(
          tournamentSeasons.map((ts) => ({ ...ts.tournament, seasons: ts.seasons }))
        );

        if (!selectedTournament && tournamentSeasons.length > 0) {
          setSelectedTournament(tournamentSeasons[0].tournament);
          setSelectedSeason(tournamentSeasons[0].seasons[0]);
        }
      } catch (error) {
        console.error('Turnuva ve sezon verisi alınırken hata:', error);
        setError('Turnuva ve sezon verileri alınırken hata oluştu.');
      } finally {
        setLoading(false);
      }
    };

    fetchSeasonsAndTournaments();
  }, [teamId]);

  // Fetch statistics when tournament or season changes
  useEffect(() => {
    if (!selectedTournament || !selectedSeason) return;

    const fetchStatistics = async () => {
      setLoading(true);
      try {
        const options = {
          method: 'GET',
          url: `https://footapi7.p.rapidapi.com/api/tournament/${selectedTournament.uniqueTournament.id}/season/${selectedSeason.id}/statistics`,
          params: {
            page: '1',
            accumulation: 'total',
            group: 'summary',
            limit: '20',
          },
          headers: {
            'x-rapidapi-key': API_KEY,
            'x-rapidapi-host': API_HOST,
          },
        };

        const response = await makeApiCallWithRetries(options);
        if (response.data.results) {
          setStatistics(response.data.results);
          
          // Logo yükleme işlemi
          const uniqueTeamIds = new Set(
            response.data.results.map(stat => stat.team.id)
          );

          const logos = {};
          for (const teamId of uniqueTeamIds) {
            try {
              const logoUrl = await fetchTeamLogo(teamId);
              if (logoUrl) {
                logos[teamId] = logoUrl;
              }
            } catch (err) {
              console.error(`Logo yüklenemedi: ${teamId}`, err);
            }
          }
          setTeamLogos(logos);
        } else {
          setError('İstatistik verisi alınırken bir hata oluştu.');
        }
      } catch (error) {
        console.error('İstatistik verisi alınırken hata:', error);
        setError('İstatistik verisi alınırken hata oluştu.');
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, [selectedTournament, selectedSeason]);

  // Handle tournament change
  const handleTournamentChange = (e) => {
    const tournamentId = parseInt(e.target.value);
    const selectedT = tournaments.find((t) => t.id === tournamentId);

    setSelectedTournament(selectedT);
    setSeasons(selectedT.seasons);
    setSelectedSeason(selectedT.seasons[0]);
  };

  // Handle season change
  const handleSeasonChange = (e) => {
    const seasonId = parseInt(e.target.value);
    const selectedS = seasons.find((s) => s.id === seasonId);
    setSelectedSeason(selectedS);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
        {animationData ? (
          <Lottie options={defaultOptions} height={150} width={150} />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="statistics-page-container mt-4">
    

      {/* İstatistik Tablosu */}
      <StatisticsTable 
        statistics={statistics} 
        teamLogos={teamLogos} 
      />
    </div>
  );
};

export default Statistics;
