import React, { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "react-lottie";

const Standings = ({ leagueId }) => {
  const [standings, setStandings] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  // Sezonları getir
  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/seasons`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await axios.request(options);
        const seasonsList = response.data.seasons || [];
        setSeasons(seasonsList);
        // En son sezonu varsayılan olarak seç
        if (seasonsList.length > 0) {
          setSelectedSeason(seasonsList[0].id);
        }
      } catch (error) {
        setError("Sezonlar yüklenirken bir hata oluştu.");
      }
    };

    fetchSeasons();
  }, [leagueId]);

  // Seçili sezonun puan durumunu getir
  useEffect(() => {
    const fetchStandings = async () => {
      if (!selectedSeason) return;

      setLoading(true);
      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/season/${selectedSeason}/standings/total`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await axios.request(options);
        const data = response.data.standings;
        
        // Birden fazla grup varsa (örn: beyaz/kırmızı)
        if (data && data.length > 1) {
          setStandings(data);
          // İlk grubu varsayılan olarak seç
          setSelectedGroup(data[0]);
        } else {
          // Tek grup varsa
          setStandings(data);
          setSelectedGroup(data[0]);
        }
      } catch (error) {
        setError("Puan durumu yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchStandings();
  }, [leagueId, selectedSeason]);

  const getPromotionColor = (promotionId) => {
    switch (promotionId) {
      case 3:
        return "bg-red-500"; // Küme düşme
      case 804:
        return "bg-blue-500"; // Şampiyonlar Ligi
      case 968:
        return "bg-orange-300"; // Avrupa Ligi Eleme
      case 503:
        return "bg-green-500"; // Konferans Ligi Eleme
      case 808:
        return "bg-orange-500"; // Avrupa Ligi
      case 6:
        return "bg-purple-500"; // Playoff
      case 14:
        return "bg-yellow-500"; // Qualification Playoffs
      case 24:
        return "bg-cyan-500"; // Champions League Qualification
      default:
        return "bg-[#0d0d0d]"; // Siyah arka plan
    }
  };

  if (loading) return <div className="text-white">Yükleniyor...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  // Birden fazla grup var mı kontrol et
  const hasMultipleGroups = standings && standings.length > 1;

  return (
    <div style={{width:"980px"}}>
      {/* Sezon ve Grup Seçici */}
      <div className="mb-6 flex items-center gap-4">
        <select
          value={selectedSeason || ""}
          onChange={(e) => setSelectedSeason(e.target.value)}
          className="bg-[#1d1d1d] text-white px-4 py-2 rounded-md border border-gray-700"
        >
          {seasons.map((season) => (
            <option key={season.id} value={season.id}>
              {season.name}
            </option>
          ))}
        </select>

        {/* Grup seçici */}
        {hasMultipleGroups && (
          <div className="flex gap-2">
            {standings.map((group) => (
              <button
                key={group.name}
                onClick={() => setSelectedGroup(group)}
                className={`px-4 py-2 rounded-md transition-colors ${
                  selectedGroup?.name === group.name
                    ? 'bg-[#2a2a2a] text-white'
                    : 'bg-[#1d1d1d] text-gray-400 hover:bg-[#2a2a2a] hover:text-white'
                }`}
              >
                {group.name.includes('Beyaz') ? 'Beyaz Grup' : 'Kırmızı Grup'}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Puan Durumu Tablosu */}
      <div className="overflow-x-auto">
        <table className="w-full text-white">
          <thead>
            <tr className="bg-[#0d0d0d] rounded-lg">
              <th className="px-4 py-2">Sıra</th>
              <th className="px-4 py-2 text-start">Takım</th>
              <th className="px-4 py-2">O</th>
              <th className="px-4 py-2">G</th>
              <th className="px-4 py-2">B</th>
              <th className="px-4 py-2">M</th>
              <th className="px-4 py-2">A</th>
              <th className="px-4 py-2">Y</th>
              <th className="px-4 py-2">AV</th>
              <th className="px-4 py-2">P</th>
            </tr>
          </thead>
          <tbody>
            {(selectedGroup?.rows || []).map((team) => {
              const promotionColor = getPromotionColor(team.promotion?.id);
              return (
                <tr
                  key={team.team.id}
                  className={`${promotionColor} bg-opacity-10 rounded-lg`}
                >
                  <td className="text-center w-[20px]">
                    <span
                      className={`inline-block px-2 py-1 text-white text-xs font-semibold rounded-full ${promotionColor}`}
                    >
                      {team.position}
                    </span>
                  </td>
                  <td className="py-2 flex items-center text-start">
                    <img 
                      src={`/logos/team/${team.team.id}.png`}
                      alt={team.team.name}
                      className="w-6 h-6 mr-2 object-contain"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/default-team-logo.png';
                      }}
                    />
                    {team.team.name}
                  </td>
                  <td className="px-4 py-2 text-center">{team.matches}</td>
                  <td className="px-4 py-2 text-center">{team.wins}</td>
                  <td className="px-4 py-2 text-center">{team.draws}</td>
                  <td className="px-4 py-2 text-center">{team.losses}</td>
                  <td className="px-4 py-2 text-center">{team.scoresFor}</td>
                  <td className="px-4 py-2 text-center">{team.scoresAgainst}</td>
                  <td className="px-4 py-2 text-center">{team.scoreDiffFormatted}</td>
                  <td className="px-4 py-2 text-center font-bold">{team.points}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Açıklama Alanı - Sadece mevcut promotionları göster */}
      <div className="space-y-2 p-3 bg-black mt-4">
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 804) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-blue-500"></div>
            <span className="text-white">Şampiyonlar Ligi</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 24) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-cyan-500"></div>
            <span className="text-white">Şampiyonlar Ligi Elemesi</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 808) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-orange-500"></div>
            <span className="text-white">Avrupa Ligi</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 968) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-orange-300"></div>
            <span className="text-white">Avrupa Ligi Eleme</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 503) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-green-500"></div>
            <span className="text-white">Konferans Ligi Eleme</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 6) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-purple-500"></div>
            <span className="text-white">Playoff</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 14) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
            <span className="text-white">Eleme Playoff</span>
          </div>
        )}
        {(selectedGroup?.rows || []).some(team => team.promotion?.id === 3) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-red-500"></div>
            <span className="text-white">Küme Düşme</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Standings; 