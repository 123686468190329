import React from 'react';
import { Link } from 'react-router-dom';

const MenuBar = ({ menuItems }) => {
  return (
    <nav className="bg-black p-4 rounded-lg">
      <ul className="flex justify-center space-x-6 rounded-lg ">
        {menuItems.map((item, index) => (
          <li key={index}>
            <Link 
              to={item.path}
              className="text-white hover:text-gray-300 transition-colors"
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuBar;
