import React, { useState, useEffect } from "react";
import axios from "axios";
import MatchRow from "./MatchRow";
import Lottie from "react-lottie";
import PlayoffBracket from './PlayoffBracket';

const LeagueFixtures = ({ leagueId }) => {
  const [fixtures, setFixtures] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [cupTrees, setCupTrees] = useState(null);
  const [isPlayoff, setIsPlayoff] = useState(false);

  // Lottie animasyonu için ayarlar
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Animasyon datasını yükle
  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const response = await fetch("/load.json");
        const data = await response.json();
        setAnimationData(data);
      } catch (err) {
        console.error("Animasyon verisi yüklenemedi:", err);
      }
    };

    loadAnimation();
  }, []);

  // Sezonları getir
  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/seasons`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await axios.request(options);
        const seasonsList = response.data.seasons || [];
        setSeasons(seasonsList);
        if (seasonsList.length > 0) {
          setSelectedSeason(seasonsList[0].id);
        }
      } catch (error) {
        setError("Sezonlar yüklenirken bir hata oluştu.");
      }
    };

    fetchSeasons();
  }, [leagueId]);

  // Haftaları getir
  useEffect(() => {
    const fetchRounds = async () => {
      if (!selectedSeason) return;

      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/season/${selectedSeason}/rounds`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await axios.request(options);
        const roundsList = response.data.rounds || [];
        
        // Normal lig haftalarını filtrele
        const normalRounds = roundsList.filter(round => {
          // Sadece 1-26 arası normal lig haftalarını al
          return round.round <= 26 && (!round.type || round.type === 1);
        });

        // Haftaları sırala
        const sortedRounds = normalRounds.sort((a, b) => a.round - b.round);
        setRounds(sortedRounds);
        
        // Mevcut haftayı ayarla
        if (response.data.currentRound) {
          const currentRoundNumber = response.data.currentRound.round;
          if (currentRoundNumber <= 26) {
            setCurrentRound(currentRoundNumber);
            setSelectedRound(currentRoundNumber);
          } else {
            // Eğer mevcut hafta 26'dan büyükse, son normal haftayı seç
            const lastNormalRound = Math.min(26, sortedRounds[sortedRounds.length - 1]?.round || 1);
            setCurrentRound(lastNormalRound);
            setSelectedRound(lastNormalRound);
          }
        } else if (sortedRounds.length > 0) {
          setSelectedRound(sortedRounds[0].round);
        }
      } catch (error) {
        setError("Haftalar yüklenirken bir hata oluştu.");
      }
    };

    fetchRounds();
  }, [leagueId, selectedSeason]);

  // Seçili haftanın maçlarını getir
  useEffect(() => {
    const fetchFixtures = async () => {
      if (!selectedSeason || !selectedRound) return;
      
      setLoading(true);
      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/season/${selectedSeason}/matches/round/${selectedRound}`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await axios.request(options);
        const matches = response.data.events || [];
        setFixtures(matches.sort((a, b) => a.startTimestamp - b.startTimestamp));
      } catch (error) {
        setError("Fikstür yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchFixtures();
  }, [leagueId, selectedSeason, selectedRound]);

  // Turnuva ağacını getir
  useEffect(() => {
    const fetchCupTree = async () => {
      if (!selectedSeason) return;

      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/season/${selectedSeason}/cuptrees`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await axios.request(options);
        // Turnuva başlamış mı kontrolü
        if (response.data.cupTrees?.length > 0 && response.data.cupTrees[0].rounds?.some(r => r.blocks?.length > 0)) {
          setCupTrees(response.data.cupTrees[0]);
        } else {
          setCupTrees(null);
        }
      } catch (error) {
        console.error("Turnuva ağacı yüklenirken hata:", error);
      }
    };

    fetchCupTree();
  }, [leagueId, selectedSeason]);

  // Turnuva tipini kontrol et
  useEffect(() => {
    if (cupTrees?.rounds?.length > 0) {
      setIsPlayoff(true);
    } else {
      setIsPlayoff(false);
    }
  }, [cupTrees]);

  // Round adını formatla
  const formatRoundName = (round) => {
    return `${round.round}. Hafta`;
  };

  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="space-y-6" style={{width:"980px"}}>
      {/* Sezon Seçici */}
      <select
        value={selectedSeason || ""}
        onChange={(e) => setSelectedSeason(e.target.value)}
        className="bg-[#1d1d1d] text-white px-4 py-2 rounded-md border border-gray-700"
      >
        {seasons.map((season) => (
          <option key={season.id} value={season.id}>
            {season.name}
          </option>
        ))}
      </select>

      {/* Format Seçici - Sadece turnuva başlamışsa göster */}
      {cupTrees?.rounds?.length > 0 ? (
        <div className="flex justify-center gap-4">
          <button
            onClick={() => setIsPlayoff(false)}
            className={`px-4 py-2 rounded ${
              !isPlayoff ? 'bg-blue-600' : 'bg-gray-700'
            } text-white`}
          >
            Lig Formatı
          </button>
          <button
            onClick={() => setIsPlayoff(true)}
            className={`px-4 py-2 rounded ${
              isPlayoff ? 'bg-blue-600' : 'bg-gray-700'
            } text-white`}
          >
            Turnuva Formatı
          </button>
        </div>
      ) : (
        <div className="text-gray-400 text-center">
          Turnuva formatı henüz başlamadı
        </div>
      )}

      {/* Hafta Seçici veya Turnuva Ağacı */}
      {isPlayoff ? (
        <PlayoffBracket cupTrees={cupTrees} />
      ) : (
        <>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setSelectedRound(prev => Math.max(1, prev - 1))}
              className="bg-[#1d1d1d] text-white px-3 py-2 rounded-md hover:bg-[#2d2d2d]"
              disabled={selectedRound === 1}
            >
              &#8592; Önceki
            </button>

            <select
              value={selectedRound || ""}
              onChange={(e) => setSelectedRound(Number(e.target.value))}
              className="bg-[#1d1d1d] text-white px-4 py-2 rounded-md border border-gray-700 min-w-[200px] text-center"
            >
              {rounds.map((round) => (
                <option 
                  key={round.round}
                  value={round.round}
                  className={currentRound === round.round ? "font-bold" : ""}
                >
                  {formatRoundName(round)}
                  {currentRound === round.round ? " (Mevcut)" : ""}
                </option>
              ))}
            </select>

            <button
              onClick={() => setSelectedRound(prev => Math.min(rounds.length, prev + 1))}
              className="bg-[#1d1d1d] text-white px-3 py-2 rounded-md hover:bg-[#2d2d2d]"
              disabled={selectedRound === rounds.length}
            >
              Sonraki &#8594;
            </button>
          </div>
          
          {loading ? (
            <div className="flex justify-center">
              {animationData ? (
                <Lottie options={defaultOptions} height={100} width={100} />
              ) : (
                <div className="text-white">Yükleniyor...</div>
              )}
            </div>
          ) : (
            <div className="fixture-list space-y-2">
              {fixtures.map((match) => (
                <MatchRow 
                  key={match.id} 
                  match={match}
                  roundName={formatRoundName(rounds.find(r => r.round === selectedRound) || {})}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LeagueFixtures; 