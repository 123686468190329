// Fixture.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FixtureList from '../components/FixtureList';
import { fetchTeamMatchesForSeason } from '../services/api';
import Lottie from 'react-lottie';
import axios from 'axios';
import { config } from '../config';

const Fixture = () => {
  const { teamSlug, teamId } = useParams();
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Lottie animasyonu için ayarlar
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const downloadLogos = async (matches) => {
    const teamIds = new Set();
    const tournamentIds = new Set();

    matches.forEach(match => {
      teamIds.add(match.homeTeam.id);
      teamIds.add(match.awayTeam.id);
      tournamentIds.add(match.tournament.uniqueTournament.id);
    });

    try {
      console.log('Logolar indiriliyor...');
      const response = await axios.post(`${config.API_URL}/api/download-logos`, {
        teamIds: Array.from(teamIds),
        tournamentIds: Array.from(tournamentIds)
      });
      
      console.log('Logo indirme sonuçları:', {
        başarılı: response.data.successCount,
        başarısız: response.data.failureCount,
        hatalar: response.data.failedLogos
      });

      if (response.data.failedLogos.length > 0) {
        setTimeout(() => {
          console.log('Başarısız logolar için yeniden deneniyor...');
          downloadLogos(matches);
        }, 5000);
      }
    } catch (error) {
      console.error('Logo indirme hatası:', error);
    }
  };

  const loadMatchesAndLogos = async () => {
    setLoading(true);
    try {
      const allMatches = await fetchTeamMatchesForSeason(teamId, '24/25');
      if (allMatches.length === 0) {
        setHasMore(false);
      }

      // Logoları arka planda indir
      downloadLogos(allMatches);

      setMatches(allMatches);
      setLoading(false);
    } catch (err) {
      setError('Maç verisi getirilirken hata oluştu');
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const response = await fetch('/load.json');
        const data = await response.json();
        setAnimationData(data);
      } catch (err) {
        console.error("Animasyon verisi yüklenemedi:", err);
      }
    };

    loadAnimation();
    loadMatchesAndLogos();
  }, [teamId]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {loading && animationData && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          zIndex: 9999
        }}>
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      )}
      {!loading && (
        <>
          <FixtureList matches={matches} />
          {!hasMore && <div>Başka maç yok.</div>}
        </>
      )}
    </div>
  );
};

export default Fixture;