import React from 'react';

const PlayoffBracket = ({ cupTrees }) => {
  if (!cupTrees?.rounds) return null;

  const translateRoundName = (description) => {
    const translations = {
      '1/8': 'Son 16',
      'Quarterfinals': 'Çeyrek Final',
      'Semifinals': 'Yarı Final',
      'Final': 'Final',
      'Round of 16': 'Son 16',
      'Quarter-finals': 'Çeyrek Final',
      'Semi-finals': 'Yarı Final',
      'Third place': 'Üçüncülük Maçı'
    };

    return translations[description] || description;
  };

  // Turları sırala ve final turunu bul
  const sortedRounds = [...cupTrees.rounds].sort((a, b) => a.order - b.order);
  const finalRound = sortedRounds.find(round => 
    round.description === 'Final' || round.name === 'Final'
  );
  const winner = finalRound?.blocks[0]?.participants?.find(p => p.winner);

  return (
    <div className="playoff-table">
      <div className="playoff-table-content">
        {sortedRounds.map((round) => (
          <div key={round.id} className="playoff-table-tour">
            <h3 className="text-white text-center mb-4">
              {translateRoundName(round.description) || translateRoundName(round.name) || `${round.order}. Tur`}
            </h3>
            <div className="playoff-table-group">
              {round.blocks.map((block) => (
                <div key={block.blockId} className="playoff-table-pair playoff-table-pair-style">
                  {block.participants?.map((participant) => {
                    const isHomeTeam = participant.order === 1;
                    return (
                      <div
                        key={participant.id}
                        className={`playoff-table-${isHomeTeam ? 'left' : 'right'}-player`}
                      >
                        <div className="flex items-center gap-2">
                          <img
                            src={`/logos/team/${participant.team.id}.png`}
                            alt={participant.team.name}
                            className="w-6 h-6 object-contain"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/placeholder.png';
                            }}
                          />
                          <span className={`${participant.winner ? 'font-bold' : ''} truncate`}>
                            {participant.team.shortName || participant.team.name}
                          </span>
                          <span className="text-gray-400 ml-auto">
                            {isHomeTeam ? block.homeTeamScore : block.awayTeamScore}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Şampiyon Takım Gösterimi */}
        {winner && (
          <div className="playoff-table-tour">
            <h3 className="text-white text-center mb-4">Şampiyon</h3>
            <div className="playoff-table-group">
              <div className="playoff-table-pair playoff-table-pair-style playoff-table-winner">
                <div className="playoff-table-champion">
                  <div className="flex items-center gap-2 p-4">
                    <div className="relative">
                      <img
                        src={`/logos/team/${winner.team.id}.png`}
                        alt={winner.team.name}
                        className="w-12 h-12 object-contain"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder.png';
                        }}
                      />
                      <div className="absolute -top-2 -right-2">
                        <span className="text-yellow-400 text-xl">👑</span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-yellow-400 font-bold text-md">
                        {winner.team.shortName || winner.team.name}
                      </span>
                      <span className="text-gray-400 text-sm">
                        Şampiyon
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayoffBracket; 