import React, { useState, useEffect } from "react";
import axios from "axios";
import StatisticsTable from "./StatisticsTable";
import Lottie from "react-lottie";
import { fetchTeamLogo } from "../services/api";

const LeagueStatistics = ({ leagueId }) => {
  const [statistics, setStatistics] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teamLogos, setTeamLogos] = useState({});
  const [animationData, setAnimationData] = useState(null);

  // Lottie animasyon ayarları
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Animasyon datasını yükle
  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const response = await fetch("/load.json");
        const data = await response.json();
        setAnimationData(data);
      } catch (err) {
        console.error("Animasyon verisi yüklenemedi:", err);
      }
    };

    loadAnimation();
  }, []);

  // API çağrısı için retry mekanizması
  const makeApiCallWithRetries = async (options, maxRetries = 3, delay = 2000) => {
    let attempts = 0;
    while (attempts < maxRetries) {
      try {
        const response = await axios.request(options);
        return response;
      } catch (error) {
        if (error.response && error.response.status === 429) {
          attempts++;
          console.warn(`429 Rate Limit: Retrying... attempt ${attempts}`);
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          throw error;
        }
      }
    }
    throw new Error('Too many attempts made, still getting 429');
  };

  // Sezonları getir
  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/seasons`,
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await makeApiCallWithRetries(options);
        const seasonsList = response.data.seasons || [];
        setSeasons(seasonsList);
        if (seasonsList.length > 0) {
          setSelectedSeason(seasonsList[0].id);
        }
      } catch (error) {
        setError("Sezonlar yüklenirken bir hata oluştu.");
      }
    };

    fetchSeasons();
  }, [leagueId]);

  // İstatistikleri getir
  useEffect(() => {
    if (!selectedSeason) return;

    const fetchStats = async () => {
      setLoading(true);
      try {
        const options = {
          method: "GET",
          url: `https://footapi7.p.rapidapi.com/api/tournament/${leagueId}/season/${selectedSeason}/statistics`,
          params: {
            page: "1",
            order: "-rating",
            accumulation: "total",
            group: "summary",
            limit: "20",
          },
          headers: {
            "x-rapidapi-key": "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023",
            "x-rapidapi-host": "footapi7.p.rapidapi.com",
          },
        };

        const response = await makeApiCallWithRetries(options);
        if (response.data.results) {
          setStatistics(response.data.results);

          // Logoları yükle
          const uniqueTeamIds = new Set(response.data.results.map(stat => stat.team.id));
          const logos = {};
          for (const teamId of uniqueTeamIds) {
            try {
              const logoUrl = await fetchTeamLogo(teamId);
              if (logoUrl) {
                logos[teamId] = logoUrl;
              }
            } catch (err) {
              console.error(`Logo yüklenemedi: ${teamId}`, err);
            }
          }
          setTeamLogos(logos);
        }
      } catch (error) {
        setError("İstatistikler yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [leagueId, selectedSeason]);

  return (
    <div className="space-y-6" style={{width:"980px"}}>
      {/* Sezon Seçici */}
      <div className="mb-6">
        <select
          value={selectedSeason || ""}
          onChange={(e) => setSelectedSeason(e.target.value)}
          className="bg-[#1d1d1d] text-white px-4 py-2 rounded-md border border-gray-700"
        >
          {seasons.map((season) => (
            <option key={season.id} value={season.id}>
              {season.name}
            </option>
          ))}
        </select>
      </div>

      {/* İstatistik Tablosu */}
      {loading ? (
        <div className="flex justify-center">
          {animationData ? (
            <Lottie options={defaultOptions} height={100} width={100} />
          ) : (
            <div className="text-white">Yükleniyor...</div>
          )}
        </div>
      ) : (
        <StatisticsTable 
          statistics={statistics} 
          teamLogos={teamLogos}
          className="text-center"
        />
      )}
    </div>
  );
};

export default LeagueStatistics; 