import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TeamDashboard from "./pages/TeamDashboard";
import League from "./pages/League";
import MatchDetail from "./pages/MatchDetail";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/match/:matchId/*" element={<MatchDetail />} />
        <Route path="/" element={<Navigate to="/besiktas/3050" />} />
        <Route path="/:teamSlug/:teamId/*" element={<TeamDashboard />} />
        <Route path="/league" element={<Navigate to="/league/champions-leauge/7" />} />
        <Route path="/league/:leaugeSlug/:leagueId" element={<League />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
