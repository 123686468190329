// MatchRow.js

import React, { useEffect, useState } from "react";
import { fetchTeamLogo, fetchTournamentLogo } from "../services/api";
import { useParams, useNavigate } from "react-router-dom";

const MatchRow = ({ match }) => {
  const navigate = useNavigate();

  const {
    awayScore,
    awayTeam,
    homeScore,
    homeTeam,
    roundInfo,
    startTimestamp,
    status,
    tournament,
  } = match;

  const [homeTeamLogo, setHomeTeamLogo] = useState(null);
  const [awayTeamLogo, setAwayTeamLogo] = useState(null);
  const [tournamentLogo, setTournamentLogo] = useState(null);

  const matchDate = new Date(startTimestamp * 1000).toLocaleString();
  const [datePart, timePart] = matchDate.split(" ");

  const homeScoreValue = homeScore?.current ?? "";
  const awayScoreValue = awayScore?.current ?? "";

  // URL'den gelen takım ID'si ile karşılaştırma
  const { teamId } = useParams();
  
  const getScoreBackground = () => {
    // Maç bitmişse (status code 100) ve skor 0-0 ise
    if (status.code === 100 && match.homeScore.current === 0 && match.awayScore.current === 0) {
      return {
        background: "#ffffff",
        color: "#000000"
      };
    }

    // Maç henüz oynanmamışsa (status code 0)
    if (status.code !== 100) {
      return {
        background: "#000000",
        color: "#ffffff"
      };
    }

    // Diğer beraberlikler için
    if (match.homeScore.current === match.awayScore.current) {
      return {
        background: "#ffffff",
        color: "#000000"
      };
    }

    const isHomeTeam = homeTeam.id === parseInt(teamId);
    const isAwayTeam = awayTeam.id === parseInt(teamId);
    
    if (isHomeTeam) {
      return {
        background: homeScoreValue > awayScoreValue
          ? "linear-gradient(to right, #a8ff00, #04ff80)"
          : homeScoreValue < awayScoreValue
          ? "linear-gradient(to right, #ff006c, #ff003d)"
          : "#ffffff",
        color: "#000000"
      };
    }
    
    if (isAwayTeam) {
      return {
        background: awayScoreValue > homeScoreValue
          ? "linear-gradient(to right, #a8ff00, #04ff80)"
          : awayScoreValue < homeScoreValue
          ? "linear-gradient(to right, #ff006c, #ff003d)"
          : "#ffffff",
        color: "#000000"
      };
    }
    
    return {
      background: "#ffffff",
      color: "#000000"
    };
  };

  const scoreBackground = getScoreBackground();

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const homeLogo = await fetchTeamLogo(homeTeam.id);
        const awayLogo = await fetchTeamLogo(awayTeam.id);
        const tournamentLogo = await fetchTournamentLogo(tournament.uniqueTournament.id);

        setHomeTeamLogo(homeLogo);
        setAwayTeamLogo(awayLogo);
        setTournamentLogo(tournamentLogo);
      } catch (error) {
        console.error("Error fetching logos:", error);
      }
    };

    fetchLogos();
  }, [homeTeam.id, awayTeam.id, tournament.uniqueTournament.id]);

  return (
    <div 
      className="row p-1 gradient-bg mb-1 rounded-lg text-white flex items-center justify-between border border-gray-700"
      onClick={() => navigate(`/match/${match.id}`)}
      style={{ cursor: 'pointer' }}
    >
      {/* Sol kısım - Turnuva ve Logo */}
      <div
        className="col-3 flex items-center justify-start bg-black p-2 rounded-lg"
        style={{ width: "160px" }}
      >
        {tournamentLogo && (
          <div className="bg-white p-1 rounded-lg mr-2 flex items-center justify-center">
            <img
              src={tournamentLogo}
              alt={`${tournament.name} logo`}
              className="tournament-logo rounded-lg"
              style={{ 
                width: "30px", 
                height: "30px",
                objectFit: "contain"
              }}
            />
          </div>
        )}
        <div className="flex flex-col justify-center">
          <div
            className="tournament text-gray-300 font-bold"
            style={{ fontSize: "11px" }}
          >
            {tournament?.name.replace("UEFA", "").trim().length > 14
              ? `${tournament.name.replace("UEFA", "").trim().substring(0, 14)}...`
              : tournament.name.replace("UEFA", "").trim()}
          </div>
          <div className="round text-xs text-gray-400 font-semibold">
            {roundInfo?.round === 636 ? 'Elemeler' : `${roundInfo?.round}. Hafta`}
          </div>
        </div>
      </div>

      {/* Orta kısım - Takımlar ve Skor */}
      <div className="col-6 flex items-center justify-center">
        <div className="flex items-center">
          <div
            className="team-name"
            style={{ width: "165px", textAlign: "right", fontWeight: "bold" }}
          >
            {homeTeam.name}
          </div>
          {homeTeamLogo && (
            <img
              src={homeTeamLogo}
              alt={`${homeTeam.name} logo`}
              className="team-logo mx-2"
              style={{ width: "35px", height: "35px" }}
            />
          )}
        </div>

        {/* Skor Kısmı */}
        <div
          className="score text-lg font-bold rounded-md"
          style={{
            background: scoreBackground.background,
            color: scoreBackground.color, // Rengi dinamik olarak al
            padding: "6px 10px",
            borderRadius: "5px",
            minWidth: "80px",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          {homeScoreValue} - {awayScoreValue}
        </div>

        <div className="flex items-center">
          {awayTeamLogo && (
            <img
              src={awayTeamLogo}
              alt={`${awayTeam.name} logo`}
              className="team-logo mx-2"
              style={{ width: "35px", height: "35px" }}
            />
          )}
          <div
            className="team-name"
            style={{ width: "165px", textAlign: "left", fontWeight: "bold" }}
          >
            {awayTeam.name}
          </div>
        </div>
      </div>

      {/* Sağ kısım - Tarih ve Saat */}
      <div
        className="col-3 flex flex-col items-center justify-center bg-black p-2 rounded-lg"
        style={{ width: "135px" }}
      >
        <div className="date text-sm text-gray-300 font-bold">
          <p className="block p-0 m-auto">{datePart}</p>
          <p className="block text-center p-0">{timePart.replace(":00", "")}</p>
        </div>
      </div>
    </div>
  );
};

export default MatchRow;