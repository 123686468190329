import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Standings from "../components/Standings";
import LeagueFixtures from "../components/LeagueFixtures";
import LeagueStatistics from "../components/LeagueStatistics";

const League = () => {
  const { leagueId } = useParams();
  const [activeTab, setActiveTab] = useState("fixtures");

  const tabs = [
    { id: "fixtures", label: "Fikstür" },
    { id: "standings", label: "Puan Durumu" },
    { id: "statistics", label: "İstatistikler" },
  ];

  return (
    <div className="min-h-screen mt-4">
      <div className="w-full">
        <div className="bg-black rounded-lg shadow-xl overflow-hidden">
          {/* Tab Başlıkları */}
          <div className="flex border-b border-gray-800 justify-center">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`px-6 py-3 text-sm font-medium ${
                  activeTab === tab.id
                    ? "text-white border-b-2 border-blue-500"
                    : "text-gray-400 hover:text-white"
                }`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Tab İçerikleri */}
          <div className="p-4">
            {activeTab === "fixtures" && <LeagueFixtures leagueId={leagueId} />}
            {activeTab === "standings" && <Standings leagueId={leagueId} />}
            {activeTab === "statistics" && <LeagueStatistics leagueId={leagueId} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default League; 