import React from 'react';

const PlayerStatistics = () => {
  return (
    <div>
      <h1>İstatistikler</h1>
      {/* İstatistik verileri burada gösterilecek */}
    </div>
  );
};

export default PlayerStatistics;
